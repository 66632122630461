body {
  font-family: Arial, sans-serif;
  background-color: #ecf0f1;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 101vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #3498db;
  padding: 20px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-title {
  margin: 0;
  font-size: 24px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info span {
  margin-right: 10px;
}

.user-info button {
  padding: 5px 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.App-content {
  flex: 1;
  padding: 20px;
}

@media (max-width: 600px) {
  .App-header {
    flex-direction: column;
  }

  .user-info {
    margin-top: 10px;
  }
}