.leaderboard-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.leaderboard-table th {
  background-color: #3498db;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}

.leaderboard-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.leaderboard-table tr:hover {
  background-color: #e8f4fd;
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .leaderboard-table {
    font-size: 14px;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 8px;
  }
}