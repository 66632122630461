.home-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  nav a {
    display: inline-block;
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #3498db;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  nav a:hover {
    background-color: #2980b9;
  }